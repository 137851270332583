import React, { Suspense, useState } from 'react';
import { isEqual } from "lodash";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import OrderHook from "./hooks/OrderHook";
import 'react-toastify/dist/ReactToastify.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConsentView from './components/sections/consentview/ConsentView';


// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Restaurant = React.lazy(() => import("./components/pages/Restaurant"));
const About = React.lazy(() => import("./components/pages/About"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Orderdetails = React.lazy(() => import("./components/pages/Orderdetails"));
const Scan = React.lazy(() => import("./components/pages/Scan"));
const Impressum = React.lazy(() => import("./components/pages/Impressum"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
// const Homeone = React.lazy(() => import("./components/pages/Homeone"));
// const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// const Homethree = React.lazy(() => import("./components/pages/Homethree"));
// const Homefour = React.lazy(() => import("./components/pages/Homefour"));
// const Blog = React.lazy(() => import("./components/pages/Blog"));
// const Blogtwo = React.lazy(() => import("./components/pages/Blogtwo"));
// const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// const Restaurantone = React.lazy(() => import("./components/pages/Restaurantone"));
// const Restauranttwo = React.lazy(() => import("./components/pages/Restauranttwo"));
// const Addrestaurant = React.lazy(() => import("./components/pages/Addrestaurant"));
// const Exdeals = React.lazy(() => import("./components/pages/Exdeals"));
// const Geolocator = React.lazy(() => import("./components/pages/Geolocator"));
// const Listview = React.lazy(() => import("./components/pages/Listview"));
// const Login = React.lazy(() => import("./components/pages/Login"));
// const Register = React.lazy(() => import("./components/pages/Register"));

function App() {
  //for hiding console in production
  if (process.env.NODE_ENV !== 'development') {
    console.log = console.warn = console.error = () => { };
  }


  const cart = OrderHook(JSON.parse(localStorage.getItem("cart")));
  console.log("Card APP JS", cart)

  const store = localStorage.getItem("cart") && isEqual(JSON.parse(localStorage.getItem("cart")), cart.cart)
    ? localStorage.getItem("cart") : localStorage.setItem("cart", JSON.stringify(cart.cart));


  const [ln, setLn] = useState("");
  function onLanguageChangeGlobalState(ln) {
    setLn(ln);
  }

  // unless set, the user cannot proceed.
  const [consent,setConsent]=useState(localStorage.getItem("consent"));

  

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        
        {!consent &&
          <ConsentView  // note that this hoc's a portal and returns it here
            onConsent={()=>{
              localStorage.setItem("consent","true");
              setConsent(true);
            }}
            parent={document.getElementById('quickmunch')}
            lang={i18n}
            onLanguageChangeGlobalState={onLanguageChangeGlobalState}
          />
        }

        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />

            <Route exact path="/scan" render={(props) => <Scan {...props} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />

            <Route path="/restaurant/:slug" render={(props) => <Restaurant {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />
            <Route path="/order-details" render={(props) => <Orderdetails {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />
            <Route path="/about" render={(props) => <About {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />
            <Route path="/checkout" render={(props) => <Checkout {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />
            <Route path="/impressum" render={(props) => <Impressum {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />
            <Route path="/terms" render={(props) => <Terms {...props} {...cart} lang={i18n} onLanguageChangeGlobalState={onLanguageChangeGlobalState} />} />

            {/* <Route path="/home-v1" component={Homeone} />
          <Route path="/home-v2" component={Hometwo} />
          <Route path="/home-v3" component={Homethree} />
          <Route path="/home-v4" component={Homefour} />
          <Route path="/blog" component={Blog} />
          <Route path="/blog-v2" component={Blogtwo} />
          <Route path="/blog-details" component={Blogdetails} />
          <Route path="/restaurant-v1" component={Restaurantone} />
          <Route path="/restaurant-v2" component={Restauranttwo} />
          <Route path="/add-restaurant" component={Addrestaurant} />
          <Route path="/ex-deals" component={Exdeals} />
          <Route path="/geo-locator" component={Geolocator} />
          <Route path="/list-view" component={Listview} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} /> */}

          </Switch>
        </Suspense>
      </Router>
    </I18nextProvider>
  );
}

export default App;
