import React from 'react';
import {createPortal} from 'react-dom';

/**
 * this component is re-usable, and is only for one message
 * and a typical "ok" button.
 * 
 * note: the highest div is to make the underlying components 
 * inaccessible while the modal message is shown.
 * 
 * note1: the message that goes in this component is meant to 
 * be passed as a component, since it might include more than
 * one paragraph or have additional elements (i.e. an anchor).
*/
export default function({btnLabel,onClick,classPrefix=null,parent,...props}){
  
  const className=classPrefix?classPrefix:'modal';

  // note that this isn't return statement :)
  let portal=createPortal(
    (<div class='modal-entire-screen'>
      <div class={`${className}-modal-div`}>
        <div class={`${className}-modal-text-div`}>
          {props.children}
        </div>
        <div class={`${className}-modal-btn-div`}>
          <button
            onClick={(e)=>{
              e.preventDefault(); // no bubbling (parent element shouldn't capture it).
              onClick();
            }}
            >{btnLabel}</button>
        </div>
      </div>
   </div>),

   parent
  );

  return portal;
}