import React from 'react';
import ModalMessage from './ModalMessage';
import '../../../assets/css/ConsentView.css';

// a HOC wrapping the ModalMessage, and modifying it to fit 
// the Consent-View.
// note that this is only the view, the reading of the localStorage
// happens in App.js for the ease of use.
export default function({lang,onConsent,parent}){

  return(
    <ModalMessage 
      onClick={onConsent}
      btnLabel={lang.t('consent.proceed')}
      classPrefix='consent'
      parent={parent}
    >
      <p>{lang.t('consent.message')}</p>
      <a href="/terms">{lang.t('consent.terms_and_conditions')}</a>
    </ModalMessage>
  );
}